import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'

const exportPdfDetails = async (baseUrl, uri, orgId, reportTitle, vm, search) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
        pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
      const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
      const pdfContent = [
          {
            columns: reportHeadData.reportHeadColumn, style: 'main_head'
          },
          { text: reportHeadData.address, style: 'address', alignment: 'center' }
        ]
        pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center', decoration: 'underline' })
        pdfContent.push({ text: '', style: 'fertilizer' })
        const allRowsHead = [
          [
            { text: vm.$t('globalTrans.division') + ' : ', alignment: 'left', style: 'thCustom', bold: true },
            { text: (vm.search.division_id ? (vm.currentLocale === 'en' ? vm.searchResult.division_name : vm.searchResult.division_name_bn) : vm.$t('globalTrans.all')), alignment: 'left', style: 'thCustom' },
            { text: vm.$t('globalTrans.district') + ' : ', alignment: 'left', style: 'thCustom', bold: true },
            { text: (vm.search.district_id ? (vm.currentLocale === 'en' ? vm.searchResult.district_name : vm.searchResult.district_name_bn) : vm.$t('globalTrans.all')), alignment: 'left', style: 'thCustom' },
            { text: vm.$t('globalTrans.upazila') + ' : ', alignment: 'left', style: 'thCustom', bold: true },
            { text: (vm.search.upazilla_id ? (vm.currentLocale === 'en' ? vm.searchResult.upazilla_name : vm.searchResult.upazilla_name_bn) : vm.$t('globalTrans.all')), alignment: 'left', style: 'thCustom' }
          ],
          [
            { text: vm.$t('dae_config.circular_type') + ' : ', alignment: 'left', style: 'thCustom', bold: true },
            { text: (search.circular_type ? (vm.currentLocale === 'en' ? vm.searchResult.circular_type_name : vm.searchResult.circular_type_name_bn) : vm.$t('globalTrans.all')), alignment: 'left', style: 'thCustom' },
            { text: vm.$t('dae_subsidy.circular') + ' : ', alignment: 'left', style: 'thCustom', bold: true },
            { text: (search.circular_id ? (vm.currentLocale === 'en' ? vm.searchResult.circular_name : vm.searchResult.circular_name_bn) : vm.$t('globalTrans.all')), alignment: 'left', style: 'thCustom' },
            { text: vm.$t('dae_subsidy.fiscal_year') + ' : ', alignment: 'left', style: 'thCustom', bold: true },
            { text: (search.fiscal_year_id ? (vm.currentLocale === 'en' ? vm.searchResult.fiscal_year : vm.searchResult.fiscal_year_bn) : vm.$t('globalTrans.all')), alignment: 'left', style: 'thCustom' }
          ]
        ]
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['9%', '25%', ' 7%', '25%', '9%', '25%'],
            body: allRowsHead
          },
          layout: {
            hLineWidth: function (i, node) {
              return 0
            },
            vLineWidth: function (i, node) {
              return 0
            }
          }
        })
        pdfContent.push({ text: vm.$t('dae_subsidy.distApplicantlist'), style: 'header2', alignment: 'left' })
        const totalCropColumns = (vm.policyCrops.length ? vm.policyCrops.length : 1)
        const totalMaterialColumns = (vm.policyMaterials.length ? vm.policyMaterials.length : 1)
        const totalColumns = totalCropColumns + totalMaterialColumns
        const allRows = []
        const header1 = [
          { text: vm.$t('globalTrans.sl_no') + '.', style: 'th', alignment: 'center', bold: true, rowSpan: 3 },
          { text: vm.$t('dae_reports.farmer_name_father_name'), style: 'th', alignment: 'center', bold: true, rowSpan: 3 },
          { text: vm.$t('dae_reports.nid_mobile_no') + '.', style: 'th', alignment: 'center', bold: true, rowSpan: 3 },
          { text: vm.$t('globalTrans.photo'), style: 'th', alignment: 'center', bold: true, rowSpan: 3 },
          { text: vm.$t('rehabilitaionAllocation.cash_tk'), style: 'th', alignment: 'center', bold: true, rowSpan: 3 },
          { text: vm.$t('dae_grant_allocation_distribution.allocation') + ' ' + vm.$t('dae_grant_allocation_distribution.quantity') + ' (' + vm.$t('dae_grant_allocation_distribution.kg') + ')', style: 'th', alignment: 'center', bold: true, colSpan: totalColumns }
        ]
        let i = 1
        while (i < totalColumns) {
          header1.push({})
          i++
        }
        header1.push({ text: vm.$t('dae_reports.recipient_signature'), style: 'th', alignment: 'center', bold: true, rowSpan: 3 },
        { text: vm.$t('dae_reports.identifier_signature'), style: 'th', alignment: 'center', bold: true, rowSpan: 3 },
        { text: vm.$t('dae_reports.distributor_signature'), style: 'th', alignment: 'center', bold: true, rowSpan: 3 })
        const header2 = [
          {},
          {},
          {},
          {},
          {},
          { text: vm.$t('dae_grant_allocation_distribution.crop_name'), style: 'th', alignment: 'center', bold: true, colSpan: totalCropColumns }
        ]
        let j = 1
        while (j < totalCropColumns) {
          header2.push({})
          j++
        }
        header2.push({ text: vm.$t('dae_grant_allocation_distribution.fertilizer'), style: 'th', alignment: 'center', bold: true, colSpan: totalMaterialColumns })
        let k = 1
        while (k < totalMaterialColumns) {
          header2.push({})
          k++
        }
        header2.push({}, {}, {})
        const header3 = [
          {},
          {},
          {},
          {},
          {}
        ]
        if (vm.policyCrops.length) {
          vm.policyCrops.forEach((item, index) => {
            header3.push({ text: (i18n.locale === 'bn') ? item.crop_name_bn : item.crop_name, style: 'th', alignment: 'center', bold: true })
          })
        } else {
          header3.push({})
        }
        if (vm.policyMaterials.length) {
          vm.policyMaterials.forEach((item, index) => {
            header3.push({ text: (i18n.locale === 'bn') ? item.material_name_bn : item.material_name, style: 'th', alignment: 'center', bold: true })
          })
        } else {
          header3.push({})
        }
        header3.push({}, {}, {})
        allRows.push(header1, header2, header3)
        let widthData
        if (vm.applicant_details.length) {
          vm.applicant_details.forEach((detail, index) => {
            let $imageColumn
            if (detail.attachment_pdf) {
              $imageColumn = { image: 'data:image/jpeg;base64,' + detail.attachment_pdf, alignment: 'center', style: 'td', width: 50 }
            } else {
              $imageColumn = { text: '', alignment: 'center', style: 'td' }
            }
            const rowItem = [
              { text: vm.$n(index + 1), alignment: 'center', style: 'td' },
              { text: vm.$t('dae_subsidy.farmer_name') + ':' + ((i18n.locale === 'bn') ? detail.farmer_name_bn : detail.farmer_name) + '\n' + vm.$t('dae_grant_allocation_distribution.farmer_father_name') + ':' + ((i18n.locale === 'bn') ? detail.father_name_bn : detail.father_name), alignment: 'center', style: 'td' },
              { text: vm.$t('dae_subsidy.nid') + ':' + vm.$n(detail.nid_no, { useGrouping: false }) + '\n' + vm.$t('dae_subsidy.mobile_no') + ':' + vm.$n(detail.mobile_no, { useGrouping: false }), alignment: 'center', style: 'td' },
              $imageColumn,
              { text: (detail.cash_tk ? vm.$n(detail.cash_tk) : '-'), alignment: 'right', style: 'td' }
            ]
            if (vm.policyCrops.length) {
              vm.policyCrops.forEach(item => {
                const cropText = vm.getCropNameData(item, detail.demand_crop_approval) + ' ' + (vm.getCropNameData(item, detail.demand_crop_approval) !== '-' ? vm.getCropUnitType(item.unit_type) : '')
                rowItem.push(
                  { text: cropText, style: 'td', alignment: 'center' }
                )
              })
            } else {
              rowItem.push({ text: '' })
            }
            if (vm.policyMaterials.length) {
              vm.policyMaterials.forEach(item => {
                const matText = vm.getMeterialNameData(item, detail.demand_material_approval) + ' ' + (vm.getMeterialNameData(item, detail.demand_material_approval) !== '-' ? vm.getMaterialUnitType(item.unit_type) : '')
                rowItem.push(
                  { text: matText, style: 'td', alignment: 'center' }
                )
              })
            } else {
              rowItem.push({ text: '' })
            }
            rowItem.push({ text: '' }, { text: '' }, { text: '' })
            allRows.push(rowItem)
          })
          widthData = ['5%', '*', '*', '*', '7%']
          let columnsNo = 1
          while (columnsNo <= totalColumns) {
            widthData.push('7%')
            columnsNo++
          }
          widthData.push('*', '*', '*')
        } else {
          allRows.push([
            { text: vm.$t('globalTrans.noDataFound'), alignment: 'center', style: 'td', colSpan: 6 + totalColumns }
          ])
          widthData = '*'
        }
        pdfContent.push({
          table: {
            headerRows: 3,
            widths: widthData,
            body: allRows
          }
        })
        const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'landscape',
        watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
          th: {
            fontSize: (i18n.locale === 'bn') ? 6.5 : 7.5,
            margin: [0.5, 0.5, 0.5, 0.5]
          },
          td: {
            fontSize: (i18n.locale === 'bn') ? 6.5 : 7.5,
            margin: [0.5, 0.5, 0.5, 0.5]
          },
          thCustom: {
            fontSize: (i18n.locale === 'bn') ? 9 : 10,
            margin: [3, 3, 3, 3]
          },
          tdCustom: {
            fontSize: (i18n.locale === 'bn') ? 9 : 10,
            margin: [3, 3, 3, 3]
          },
          search: {
            fontSize: (i18n.locale === 'bn') ? 10 : 8,
            margin: [3, 3, 3, 3]
          },
          fertilizer: {
            margin: [5, 0, 0, 5]
          },
          header: {
            fontSize: 12,
            margin: [0, 0, 0, 4]
          },
          header2: {
            fontSize: 12,
            margin: [0, 10, 0, 20]
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 5]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          krishi: {
            margin: [0, -5, 0, 15],
            alignment: 'center'
          },
          header3: {
            fontSize: 9,
            margin: [0, 0, 0, 0]
          },
          address: {
            fontSize: 9,
            margin: [0, -10, 0, 0]
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).print()
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
