<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('dae_reports.distribution_report') }}</h4>
          </template>
          <template v-slot:body>
            <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
              <b-form  @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset">
                <b-row>
                  <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <ValidationProvider name="Division" vid="division_id" rules="required|min_value:1">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="division_id"
                          slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('globalTrans.division')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                            plain
                            v-model="search.division_id"
                            :options="divisionList"
                            id="division_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            :disabled="authLocation.division_id != 0"
                        >
                          <template v-slot:first>
                            <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <ValidationProvider name="District" vid="district_id" rules="required|min_value:1">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="district_id"
                          slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{ $t('org_pro_district.district') }} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                            plain
                            v-model="search.district_id"
                            :options="districtList"
                            id="district_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            :disabled="authLocation.district_id != 0"
                        >
                          <template v-slot:first>
                            <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <ValidationProvider name="Upazilla" vid="upazilla_id" rules="required|min_value:1">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="upazilla_id"
                          slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{ $t('org_pro_upazilla.upazilla') }} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                            plain
                            v-model="search.upazilla_id"
                            :options="upazilaList"
                            id="upazilla_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            :disabled="authLocation.upazilla_id != 0"
                        >
                          <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <ValidationProvider name="Fiscal Year" vid="fiscal_year_id" rules="required|min_value:1">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="fiscal_year_id"
                          slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('dae_subsidy.fiscal_year')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                            plain
                            v-model="search.fiscal_year_id"
                            :options="fiscalYearList"
                            id="fiscal_year_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                        >
                          <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <ValidationProvider name="Circular Type" vid="circular_type" rules="required|min_value:1">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="circular_type"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                          {{$t('dae_subsidy.circular_ty')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                            plain
                            v-model="search.circular_type"
                            :options="circularTypeList"
                            id="circular_type"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                              <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <ValidationProvider name="Circular" vid="circular_id" rules="required|min_value:1">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="circular_id"
                          slot-scope="{ valid, errors }">
                        <template v-slot:label>
                          {{ $t('dae_subsidy.circular') }} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                            plain
                            v-model="search.circular_id"
                            :options="circularList"
                            id="circular_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                        >
                          <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col xs="12" sm="12" md="12">
                    <b-button type="submit" variant="primary float-right">{{ $t('globalTrans.search')}}</b-button>
                  </b-col>
                </b-row>
              </b-form>
            </ValidationObserver>
          </template>
        </iq-card>
        <b-row>
          <b-col md="12" v-if="showData">
            <b-overlay>
              <iq-card>
                <template v-slot:body>
                  <b-row>
                    <b-col md="12">
                      <iq-card>
                        <template v-slot:headerTitle>
                          <h4 class="card-title">{{ $t('dae_reports.distribution_report') }}</h4>
                        </template>
                        <template v-slot:headerAction>
                          <b-button class="btn_add_new" @click="pdfExport">
                            <i class="fas fa-print"></i>{{  $t('globalTrans.print') }}
                          </b-button>
                        </template>
                        <template v-slot:body>
                          <b-overlay :show="loading">
                            <b-row mt-5>
                              <b-col md="12" class="table-responsive">
                                <div style="border: 2px solid;margin:10px;padding:10px">
                                  <b-row>
                                    <b-col>
                                      <list-report-head :base-url="incentiveGrantServiceBaseUrl" uri="/common-config/report-heading/detail" :org-id="2">
                                        {{ $t('dae_reports.distribution_report') }}
                                      </list-report-head>
                                    </b-col>
                                  </b-row>
                                  <div class="text-black">
                                    <b-row class="my-4">
                                      <b-col md="4"><strong>{{ $t('globalTrans.division') }}: </strong>{{ search.division_id ? ($i18n.locale === 'en' ? searchResult.division_name : searchResult.division_name_bn) : $t('globalTrans.all')  }}</b-col>
                                      <b-col md="4"><strong>{{ $t('globalTrans.district') }}: </strong>{{ search.district_id ? ($i18n.locale === 'en' ? searchResult.district_name : searchResult.district_name_bn) : $t('globalTrans.all')  }}</b-col>
                                      <b-col md="4"><strong>{{ $t('globalTrans.upazila') }}: </strong>{{ search.upazilla_id ? ($i18n.locale === 'en' ? searchResult.upazilla_name : searchResult.upazilla_name_bn) : $t('globalTrans.all')  }}</b-col>
                                      <b-col md="4"><strong>{{ $t('dae_config.circular_type') }}: </strong>{{ search.circular_type ? ($i18n.locale === 'en' ? searchResult.circular_type_name : searchResult.circular_type_name_bn) : $t('globalTrans.all')  }}</b-col>
                                      <b-col md="4"><strong>{{ $t('dae_subsidy.circular') }}: </strong>{{ search.circular_id ? ($i18n.locale === 'en' ? searchResult.circular_name : searchResult.circular_name_bn) : $t('globalTrans.all')  }}</b-col>
                                      <b-col md="4"><strong>{{ $t('dae_subsidy.fiscal_year') }}: </strong>{{ search.fiscal_year_id ? ($i18n.locale === 'en' ? searchResult.fiscal_year : searchResult.fiscal_year_bn) : $t('globalTrans.all')  }}</b-col>
                                    </b-row>
                                  </div>
                                  <b-row>
                                    <div class="col-sm-12 col-md-12">
                                      <h4>{{ $t('dae_subsidy.distApplicantlist') }}</h4>
                                      <b-table-simple striped bordered small class="mt-2 table-responsive">
                                        <b-tr>
                                          <b-th rowspan="3" class="text-center">{{ $t('globalTrans.sl_no') }}.</b-th>
                                          <b-th rowspan="3" class="text-center">{{ $t('dae_reports.farmer_name_father_name') }}</b-th>
                                          <b-th rowspan="3" class="text-center">{{ $t('dae_reports.nid_mobile_no') }}.</b-th>
                                          <b-th rowspan="3" class="text-center">{{ $t('globalTrans.photo') }}</b-th>
                                          <b-th rowspan="3" class="text-center">{{ $t('rehabilitaionAllocation.cash_tk') }}</b-th>
                                          <b-th :colspan="(policyCrops.length ? policyCrops.length : 1) + (policyMaterials.length ? policyMaterials.length : 1)" class="text-center">{{ $t('dae_grant_allocation_distribution.allocation') }} {{ $t('dae_grant_allocation_distribution.quantity') }} ({{ $t('dae_grant_allocation_distribution.kg') }})</b-th>
                                          <b-th rowspan="3" class="text-center">{{ $t('dae_reports.recipient_signature') }}</b-th>
                                          <b-th rowspan="3" class="text-center">{{ $t('dae_reports.identifier_signature') }}</b-th>
                                          <b-th rowspan="3" class="text-center">{{ $t('dae_reports.distributor_signature') }}</b-th>
                                        </b-tr>
                                        <b-tr>
                                          <b-th :colspan="policyCrops.length ? policyCrops.length : 1"  class="text-center">{{ $t('dae_grant_allocation_distribution.crop_name') }}</b-th>
                                          <b-th :colspan="policyMaterials.length ? policyMaterials.length : 1" class="text-center">{{ $t('dae_grant_allocation_distribution.fertilizer') }}</b-th>
                                        </b-tr>
                                        <b-tr>
                                          <b-th v-for="(pCrop,pCropIndex) in policyCrops" :key="pCropIndex+100" class="text-center">
                                            {{ ($i18n.locale === 'bn') ? pCrop.crop_name_bn : pCrop.crop_name }}
                                          </b-th>
                                          <b-th v-for="(pMatr,pMatrIndex) in policyMaterials" :key="pMatrIndex+200" class="text-center">
                                            {{ ($i18n.locale === 'bn') ? pMatr.material_name_bn : pMatr.material_name }}
                                          </b-th>
                                        </b-tr>
                                        <template v-if="applicant_details.length">
                                          <b-tr v-for="(detail,index) in applicant_details" :key="index">
                                            <b-td class="text-center">{{ $n(index+1) }}</b-td>
                                            <b-td>
                                              {{ $t('dae_subsidy.farmer_name') }}: {{ ($i18n.locale === 'bn') ? detail.farmer_name_bn : detail.farmer_name }} <br/>
                                              {{ $t('dae_grant_allocation_distribution.farmer_father_name') }}: {{ ($i18n.locale === 'bn') ? detail.father_name_bn : detail.father_name }}
                                            </b-td>
                                            <b-td>
                                              {{ $t('dae_subsidy.nid') }}: {{ $n(detail.nid_no, { useGrouping: false }) }} <br/>
                                              {{ $t('dae_subsidy.mobile_no') }}: {{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(detail.mobile_no, { useGrouping: false } ) }}
                                            </b-td>
                                            <b-td>
                                              <img :src="incentiveGrantServiceBaseUrl + detail.attachment" style="width:100px" v-if="detail.attachment"/>
                                            </b-td>
                                            <b-td class="text-right">
                                              {{ detail.cash_tk ? $n(detail.cash_tk) : '-' }}
                                            </b-td>
                                            <b-td v-for="(sinPCrop,sinPCropIndex) in policyCrops" :key="sinPCropIndex + 300" class="text-center">
                                              {{ getCropNameData(sinPCrop,detail.demand_crop_approval) }} {{ getCropNameData(sinPCrop,detail.demand_crop_approval) !== '-' ? getCropUnitType(sinPCrop.unit_type) : '' }}
                                            </b-td>
                                            <b-td v-for="(sinPMaterial,sinPMaterialIndex) in policyMaterials" :key="sinPMaterialIndex + 400" class="text-center">
                                              {{ getMeterialNameData(sinPMaterial,detail.demand_material_approval) }} {{ getMeterialNameData(sinPMaterial,detail.demand_material_approval) !== '-' ? getMaterialUnitType(sinPMaterial.unit_type) : '' }}
                                            </b-td>
                                            <b-td></b-td>
                                            <b-td></b-td>
                                            <b-td></b-td>
                                          </b-tr>
                                        </template>
                                        <template v-else>
                                          <b-tr>
                                           <b-td :colspan="5 + (policyCrops.length ? policyCrops.length : 1) + (policyMaterials.length ? policyMaterials.length : 1)" class="text-center">{{ $t('globalTrans.noDataFound') }}</b-td>
                                            <b-td></b-td>
                                            <b-td></b-td>
                                            <b-td></b-td>
                                          </b-tr>
                                        </template>
                                      </b-table-simple>
                                    </div>
                                  </b-row>
                                </div>
                              </b-col>
                            </b-row>
                          </b-overlay>
                        </template>
                      </iq-card>
                    </b-col>
                  </b-row>
                </template>
              </iq-card>
            </b-overlay>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { distributionReport } from '../../api/routes'
import flatpickr from 'flatpickr'
import ListReportHead from '@/components/custom/ListReportHead.vue'
import Pdf from './pdf'
import excel from 'vue-excel-export'
import Vue from 'vue'
Vue.use(excel)

export default {
    props: ['id'],
    components: {
      ListReportHead
    },
    created () {
      this.setAuthLocationInfo()
    },
    data () {
      return {
        search: {
          division_id: 0,
          district_id: 0,
          upazilla_id: 0,
          circular_type: 0,
          circular_id: 0,
          fiscal_year_id: 0
        },
        searchResult: {
          division_name: '',
          division_name_bn: '',
          district_name: '',
          district_name_bn: '',
          upazilla_name: '',
          upazilla_name_bn: '',
          circular_type_name: '',
          circular_type_name_bn: '',
          circular_name: '',
          circular_name_bn: '',
          fiscal_year: '',
          fiscal_year_bn: ''
        },
        incentiveGrantServiceBaseUrl: incentiveGrantServiceBaseUrl,
        datas: [],
        showData: false,
        districtList: [],
        upazilaList: [],
        circularList: [],
        policyCrops: [],
        policyMaterials: [],
        applicant_details: [],
        authLocation: {
          division_id: 0,
          district_id: 0,
          upazilla_id: 0
        }
      }
    },
    mounted () {
      core.index()
      flatpickr('.datepicker', {})
    },
    computed: {
      circularTypeList: function () {
        return this.$store.state.incentiveGrant.commonObj.subsidyCircularTypeList.filter(item => item.value !== 4 && item.value !== 1)
      },
      divisionList: function () {
        let divisionList = this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
        if (this.authLocation.division_id) {
          divisionList = divisionList.filter(item => item.value === this.authLocation.division_id)
        }
        return divisionList
      },
      fiscalYearList () {
        return this.$store.state.commonObj.fiscalYearList
      },
      loading () {
        return this.$store.state.commonObj.loading
      },
      currentLocale () {
        return this.$i18n.locale
      }
    },
    watch: {
      'search.division_id': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.districtList = this.getDistrictList(newVal)
        }
      },
      'search.district_id': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.upazilaList = this.getUpazilaList(newVal)
        }
      },
      'search.circular_type': function (newValue, oldVal) {
        this.circularList = this.getCircularList()
      }
    },
    methods: {
      pdfExport () {
        const reportTitle = this.$t('dae_reports.distribution_report')
        Pdf.exportPdfDetails(incentiveGrantServiceBaseUrl, '/common-config/report-heading/detail', 2, reportTitle, this, this.search)
      },
      async searchData () {
        this.$store.dispatch('mutateCommonProperties', { loading: true })
        this.showData = true
        const params = Object.assign({}, this.search)
        await RestApi.getData(incentiveGrantServiceBaseUrl, distributionReport, params).then(response => {
          if (response.success) {
              this.policyCrops = response.cropList
              this.policyMaterials = response.materialList
              this.applicant_details = this.getDemandRelatinalData(response.data)
              if (response.data) {
                if (this.search.division_id) {
                  const division = this.$store.state.commonObj.divisionList.find(div => div.value === this.search.division_id)
                  this.searchResult.division_name = division !== undefined ? division.text_en : ''
                  this.searchResult.division_name_bn = division !== undefined ? division.text_bn : ''
                }
                if (this.search.district_id) {
                  const district = this.$store.state.commonObj.districtList.find(div => div.value === this.search.district_id)
                  this.searchResult.district_name = district !== undefined ? district.text_en : ''
                  this.searchResult.district_name_bn = district !== undefined ? district.text_bn : ''
                }
                if (this.search.upazilla_id) {
                  const upazilla = this.$store.state.commonObj.upazilaList.find(div => div.value === this.search.upazilla_id)
                  this.searchResult.upazilla_name = upazilla !== undefined ? upazilla.text_en : ''
                  this.searchResult.upazilla_name_bn = upazilla !== undefined ? upazilla.text_bn : ''
                }
                if (this.search.circular_type) {
                  const circularType = this.$store.state.incentiveGrant.commonObj.subsidyCircularTypeList.find(div => div.value === this.search.circular_type)
                  this.searchResult.circular_type_name = circularType !== undefined ? circularType.text_en : ''
                  this.searchResult.circular_type_name_bn = circularType !== undefined ? circularType.text_bn : ''
                }
                if (this.search.circular_id) {
                  const circular = this.$store.state.incentiveGrant.commonObj.circularList.find(div => div.value === this.search.circular_id)
                  this.searchResult.circular_name = circular !== undefined ? circular.text_en : ''
                  this.searchResult.circular_name_bn = circular !== undefined ? circular.text_bn : ''
                }
                if (this.search.fiscal_year_id) {
                  const fiscalYearObj = this.fiscalYearList.find(obj => obj.value === parseInt(this.search.fiscal_year_id))
                  this.searchResult.fiscal_year = fiscalYearObj?.text_en
                  this.searchResult.fiscal_year_bn = fiscalYearObj?.text_bn
                }
              }
          } else {
            this.policyCrops = []
            this.policyMaterials = []
            this.applicant_details = []
            this.showData = true
          }
        })
        this.$store.dispatch('mutateCommonProperties', { loading: false })
      },
      getDemandRelatinalData (data) {
        const listData = data.map((item, index) => {
          const deReqObj = {
            far_general_info_id: item.far_general_info_id,
            subs_demand_id: item.id,
            land_quantity: item.land_quantity,
            farmer_name: item.farmer_data.name,
            farmer_name_bn: item.farmer_data.name_bn,
            father_name: item.farmer_data.father_name,
            father_name_bn: item.farmer_data.father_name_bn,
            nid_no: item.farmer_data.nid_no,
            mobile_no: item.farmer_data.mobile_no,
            attachment: item.farmer_data.attachment,
            attachment_pdf: item.farmer_data.attachment_pdf,
            demandCollectioCropList: item.demand_collection_details,
            payroll_details: item.farmer_data.payroll_details,
            cash_tk: (item.farmer_data.payroll_details ? item.farmer_data.payroll_details.cash_tk : 0),
            crops: [],
            material: []
          }
          this.policyCrops.map(tmp => {
            deReqObj.crops.push({ id: tmp.crop_id, quantity: tmp.quantity })
          })
          this.policyMaterials.map(tmpMt => {
            deReqObj.material.push({ id: tmpMt.material_id, quantity: tmpMt.quantity })
          })
          return Object.assign({}, item, deReqObj)
        })
        return listData
      },
      getDistrictList (DivisionId) {
        let list = this.$store.state.commonObj.districtList.filter(item => item.status === 0 && item.division_id === DivisionId)
        if (this.authLocation.district_id) {
          list = list.filter(item => item.value === this.authLocation.district_id)
        }
        return list.map(item => {
          if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
          } else {
            return { value: item.value, text: item.text_en }
          }
        })
      },
      getUpazilaList (districtId = null) {
        let upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
        if (this.authLocation.upazilla_id) {
          upazilaList = upazilaList.filter(item => item.value === this.authLocation.upazilla_id)
        }
        if (districtId) {
          return upazilaList.filter(upazila => upazila.district_id === districtId)
        }
        return upazilaList
      },
      getCircularList () {
        const circularList = this.$store.state.incentiveGrant.commonObj.circularList.filter(item => item.status === 1 && item.fiscal_year_id === this.search.fiscal_year_id && item.type === this.search.circular_type)
        return circularList.map(item => {
          if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
          } else {
            return { value: item.value, text: item.text }
          }
        })
      },
      getCropNameData (singleCrop, detail) {
        const objectData = detail.find(item => item.crop_id === singleCrop.crop_id)
        return objectData !== undefined && objectData.quantity ? this.$n(objectData.quantity) : '-'
      },
      getCropUnitType (unit) {
        if (unit === 1) {
          return this.$t('dae_grant_allocation_distribution.kg')
        }
      },
      getMeterialNameData (singleMetrial, details) {
        const objectData = details.find(item => item.material_id === singleMetrial.material_id)
        return objectData !== undefined && objectData.quantity ? this.$n(objectData.quantity) : '-'
      },
      getMaterialUnitType (unit) {
        if (unit === 1) {
          return this.$t('dae_grant_allocation_distribution.kg')
        }
      },
      setAuthLocationInfo () {
        let location = {
          division_id: 0,
          district_id: 0,
          upazilla_id: 0
        }
        const authUser = this.$store.state.Auth.authUser
        if (!(authUser.role_id === 1 || authUser.is_org_admin)) {
          const office = this.$store.state.commonObj.officeList.find(item => item.value === authUser.office_id)
          if (typeof office !== 'undefined') {
            location = {
              division_id: office.division_id,
              district_id: office.district_id,
              upazilla_id: office.upazilla_id
            }
          }
        }
        this.search = Object.assign({}, this.search, location)
        this.authLocation = Object.assign({}, this.authLocation, location)
      }
  }
}
</script>
